type StatusType = "success" | "error" | "warning" | "info"
export interface StatusAlert {
  type: StatusType
  message: string
  showStatus: boolean
}

export function useStatus() {
  const statusAlert = reactive({
    message: "",
    showStatus: false,
    type: "info",
  } as StatusAlert)

  function setStatus(type: StatusType, message: string) {
    statusAlert.type = type
    statusAlert.message = message
    statusAlert.showStatus = true
  }

  function resetStatus() {
    statusAlert.showStatus = false
    // statusAlert.message = "";
    // statusAlert.type = "info";
  }

  return {
    resetStatus,
    setStatus,
    statusAlert: readonly(statusAlert),
  }
}
