
















import type { StatusAlert } from "@/composables/status"

export default defineComponent({
  props: {
    status: {
      required: true,
      type: Object as () => StatusAlert,
    },
  },
  setup(props, { emit }) {
    const show = computed(() => props.status.showStatus)
    const timeout = ref(0)

    function updateTimeout() {
      timeout.value -= 2

      if (timeout.value <= 0) {
        emit("close")
      } else {
        setTimeout(updateTimeout, 100)
      }
    }

    watch(show, (val: boolean) => {
      if (val) {
        if (timeout.value == 0) {
          setTimeout(updateTimeout, 100)
        }
        timeout.value = 100
      }
    })

    return { show, timeout }
  },
})
